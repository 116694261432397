<template>
  <div id="app">
    <h1>Hey Mo, do you work tomorrow?</h1>
    <div id="face-holder">
    <FaceComponent v-for="face in faces" :work="face.work" :key="face">
      {{ face.caption }}
    </FaceComponent>
    </div>
  </div>
</template>

<script>
import FaceComponent from './components/FaceComponent.vue'
export default {
  name: 'App',
  components: {
    FaceComponent
  },
  data() {
    return {
      daysUntilOffWork: 0,
      testDay: 4,
      schedule: [
        { day: 'Sunday', work: false},
        { day: 'Monday', work: false},
        { day: 'Tuesday',work:true},
        { day: 'Wednesday',work:true},
        { day: 'Thursday',work:true},
        { day: 'Friday',work:true},
        { day: 'Saturday',work:true},
      ],
      faces: [
        //{ work: true, caption: 'aghhh I have work tomorrow' },
        //{ work: true, caption: 'and the day after thaaat' },
        //{ work: false, caption: 'but not the day after that' }
      ]
    }
  },

  methods: {
    getDays(){
      const todayDayOfWeek = new Date().getDay();
      console.log('Today is: ' + todayDayOfWeek);

      // get tomorrows day of the week as a string
      const tomorrowDayOfWeek = (todayDayOfWeek + 1) % 7;
      console.log('Tomorrow is: ' + tomorrowDayOfWeek);

      // get tomorrows index in the schedule array
      const tomorrowIndex = this.schedule.findIndex(day => day.day === this.schedule[tomorrowDayOfWeek].day);
      console.log('Tomorrow index is: ' + tomorrowIndex);

 // starting at tomorrows index in the schedule array, loop through the schedule array until you find a day where work is false
    // if you reach the end of the array, loop back to the beginning
    for(let i = 0; i < this.schedule.length; i++){
      let scheduleIndex = (tomorrowIndex + i) % this.schedule.length;
      if(this.schedule[scheduleIndex].work === false && i === 0){
        this.faces.push({ work: false, caption: 'I dont work tomorrow' });
        return;
      }
      else if(this.schedule[scheduleIndex].work === false && i !== 0){
        this.faces.push({ work: false, caption: 'but not the day after that' });
        return;
      } else if (this.schedule[scheduleIndex].work === true && i == 0){
        this.faces.push({ work: true, caption: 'aghhh I have work tomorrow' });
      } else{
        this.faces.push({ work: true, caption: 'and the day after thaaat' });
      }
    }


    }
  },
  mounted() {

  this.getDays();



  }

}
</script>

<style>
body{
  background-color: #f7bac5;
}

h1{
  text-align: center;
  font-size: 2.5em;
  margin-top: 2rem;
}

#app{
  justify-content: center;
  font-family: "Arimo", sans-serif;
}


@media screen and (max-width: 1100px){
  #face-holder{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
}

@media screen and (min-width: 1100px){
  #face-holder{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30vh;
}
  
}



</style>