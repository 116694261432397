<template><div class="card">
  <div >
    <img class="face" :src="work ? require('@/assets/agony.png') : require('@/assets/success.png')">
  </div>
  <div class="caption"> 
    <slot></slot>
  </div></div>
</template>

<script>
export default {
    name: 'FaceComponent',
    props: {
        work: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style>
.card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-color: #ffffff;
    margin: 15px;
    padding: 10px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.6); 
}

.face {
   width: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.caption {
    text-align: center;
    font-size: 1.7em;
    font-weight: bold;
    color: #333;
    width: 100%;
    margin-top: 0.5rem;
}

@media  (max-width: 1100px) {
    .card {
        width: 80vw;
        height: 300px;
    }
    
}

</style>